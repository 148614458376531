import styled from 'styled-components';
import Img from 'gatsby-image';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.5em;
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-right: 2%;
  margin-bottom: 1.5em;
  background: ${props => props.theme.colors.grey};
  border: 3px solid black;
  &:nth-child(2n) {
    margin-right: 0;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  ${media.tablet`
  width: 100%;
  margin-right: 0;
  `}
`;

export const CardImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 250px;
  border-bottom: 3px solid black;
  ${media.mobile`
  min-height: 225px;
  `}
`;

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5em;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.colors.grey};
  h2 {
    margin-bottom: 1em;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Button = styled.button`
  display: flex;
  width: 100%;
  border: none;
  background: black;
  color: white;
  height: 50px;
  text-align: center;
  justify-content: center;
  font-weight: 500;
`;
