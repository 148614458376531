import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  width: 100%;
  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  height: 180px;
  padding-top: 20px;
  align-items: flex-start;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  ${media.tablet`
  height: 140px;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 55px;
  align-items: center;
  padding: 0 60px;
  ${media.tablet`
  padding: 0 40px;
  `}
  ${media.mobile`
  padding: 0 20px;
  `}
`;

export const Logo = styled(Img)`
  width: 190px;
  ${media.mobile`
  width: 150px;
  `}
`;

export const LogoWrapper = styled.div`
  width: 50%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  ${media.tablet`
  justify-content: flex-start;
  `}
`;

export const Button = styled.button`
  background: white;
  border: 3px solid black;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  height: 50px;
  width: 130px;
  ${media.tablet`
  width: 100px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-end;
  ${media.tablet`
  display: none;
  `}
`;

export const MenuButtonWrapper = styled.div`
  width: 50%;
  display: none;
  justify-content: flex-end;
  ${media.tablet`
  display: flex;
  `}
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.tablet`
  display: none;
  `}
`;

export const NavItem = styled(Link)`
  color: white;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  ${NavItem} {
    margin-right: 1em;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    &:last-child {
      margin: 0;
    }
    &:hover {
      color: white;
      text-decoration: none;
      position: relative;
      &:after {
        content: '';
        height: 2px;
        bottom: 0px;
        left: 0;
        width: 100%;
        background: white;
        position: absolute;
      }
    }
  }
`;

export const MobileNavItem = styled(Link)`
  color: Black;
  margin-bottom: 10%;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 34px;
  &[aria-current='page'] {
    background: black;
    color: white;
    padding: 0 10px;
    pointer-events: none;
  }
  &:hover {
    text-decoration: none;
    color: black;
  }
  ${media.tablet`
  font-size: 28px;
  `}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid black;
  background: white;
  z-index: 2000;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: ${props => (props.open ? '1' : '0')};
  transform: translateY(${props => (props.open ? '0%' : '100%')});
  ${media.tablet_up`
    display: none !important;
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  border: none;
  transform: rotate(45deg);
  background: white;
  &:before {
    position: absolute;
    height: 4px;
    width: 100%;
    top: calc(50% - 2px);
    left: 0;
    content: '';
    background: black;
  }
  &:after {
    position: absolute;
    height: 4px;
    width: 100%;
    top: calc(50% - 2px);
    left: 0;
    content: '';
    background: black;
    transform: rotate(90deg);
  }
`;
