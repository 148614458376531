import React, { useState } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import {
  Wrapper,
  Row,
  Logo,
  LogoWrapper,
  Button,
  ButtonWrapper,
  MenuButtonWrapper,
  NavWrapper,
  Nav,
  NavItem,
  MobileMenuWrapper,
  CloseButton,
  MobileNavItem,
} from './Header.css';

function Header() {
  const [menuOpen, toggleMenu] = useState(false);
  return (
    <Wrapper>
      <Row>
        <LogoWrapper>
          <StaticQuery
            query={graphql`
              query {
                file(relativePath: { eq: "logo-white.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            `}
            render={data => (
              <Link to="/">
                <Logo fluid={data.file.childImageSharp.fluid} />
              </Link>
            )}
          />
        </LogoWrapper>
        <ButtonWrapper>
          <Link to="/contact">
            <Button>Contact Us</Button>
          </Link>
        </ButtonWrapper>
        <MenuButtonWrapper onClick={() => toggleMenu(true)}>
          <Button>Menu</Button>
        </MenuButtonWrapper>
      </Row>
      <Row>
        <NavWrapper>
          <Nav>
            <NavItem to="/">Home</NavItem>
            <NavItem to="/residential">Residential</NavItem>
            <NavItem to="/commercial">Commercial</NavItem>
            <NavItem to="/gallery">Gallery</NavItem>
            <NavItem to="/contact">Contact</NavItem>
          </Nav>
        </NavWrapper>
      </Row>
      <MobileMenuWrapper open={menuOpen}>
        <CloseButton onClick={() => toggleMenu(false)} />
        <MobileNavItem to="/">Home</MobileNavItem>
        <MobileNavItem to="/residential">Residential</MobileNavItem>
        <MobileNavItem to="/commercial">Commercial</MobileNavItem>
        <MobileNavItem to="/gallery">Gallery</MobileNavItem>
        <MobileNavItem to="/contact">Contact</MobileNavItem>
      </MobileMenuWrapper>
    </Wrapper>
  );
}

export default Header;
