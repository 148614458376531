import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import media from '../../styles/MediaQueries';

export const Wrapper = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-wrap: wrap;
`;

export const CTA = styled.div`
  background: white;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 60px;

  ${media.tablet`
  padding: 0 40px;
 `};

  ${media.mobile`
 padding: 0 20px;
 `};
`;

export const CTAText = styled.h4`
  color: black;
  position: relative;
  z-index: 5;
  font-size: 34px;
  ${media.tablet`
  font-size: 28px;
  `}
  &:after {
    content: '';
    height: 24px;
    background: ${props => props.theme.colors.grey};
    width: 120%;
    position: absolute;
    bottom: 0;
    left: -10%;
    z-index: -1;
    ${media.mobile`
      width: 100%;
      left: 0;
    `}
  }
`;

export const BoxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
`;

export const Box = styled.div`
  display: flex;
  width: 500px;
  max-width: 100%;
  border: 3px solid black;
  margin: 0 auto;
`;

export const BB = styled.button`
  display: flex;
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  justify-content: center;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  a {
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
`;

export const BW = styled.button`
  display: flex;
  width: 100%;
  background: white;
  text-align: center;
  justify-content: center;
  height: 50px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  a {
    color: black;
    text-decoration: none;
    height: 100%;
    width: 100%;
    font-family: 'Poppins';
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
`;

export const Top = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  padding: 0 60px;

  ${media.tablet`
  padding: 0 40px;
  flex-wrap: wrap;
 `};

  ${media.mobile`
 padding: 0 20px;
 `};
`;

export const Logo = styled(Img)`
  width: 215px;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  ${media.tablet`
  justify-content: center;
 `};
`;

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${media.tablet`
  justify-content: center;
  margin-top: 20px;
  `}
`;

export const NavItem = styled(Link)`
  color: black;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;

  ${media.tablet`
  flex-wrap: wrap;
  `}
  ${NavItem} {
    margin-right: 1em;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    &:last-child {
      margin: 0;
    }
    &:hover {
      color: black;
      text-decoration: none;
      position: relative;
      &:after {
        content: '';
        height: 2px;
        bottom: 0px;
        left: 0;
        width: 100%;
        background: black;
        position: absolute;
      }
    }
  }
`;

export const Bottom = styled.div`
  background: ${props => props.theme.colors.grey};
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 30px;
`;

export const BottomInner = styled.div`
  padding: 0 60px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 0 40px;
  }
  ${media.tablet`
  padding: 0 20px;
  flex-wrap: wrap;
  `}
`;

export const CopyWrapper = styled.div`
  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

export const Copy = styled.div`
  color: black;
  span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
  }
`;

export const SocialWrapper = styled.div`
  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SocialNav = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    margin-right: 1em;
    &:last-child {
      margin: 0;
    }
  }
`;
