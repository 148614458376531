import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../containers/Layout/Layout';
import HeroTitle from '../components/HeroTitle/HeroTitle';
import ProjectFeed from '../components/ProjectFeed/ProjectFeed';
import SEO from '../components/SEO';
import { ProjectWrapper } from '../styles/pages/collection.css';

const Residential = ({ data }) => {
  return (
    <Layout>
      <SEO meta={data.page.seoMetaTags} />
      <HeroTitle
        title={data.page.title}
        image={data.image.residentialProjectPageImage.fluid}
      />
      <ProjectWrapper>
        <ProjectFeed projects={data.projects.nodes} />
      </ProjectWrapper>
    </Layout>
  );
};

Residential.propTypes = {
  data: PropTypes.object.isRequired,
};

export const commercialQuery = graphql`
  {
    projects: allDatoCmsProject(
      filter: { projectType: { eq: "Residential" } }
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      nodes {
        projectTitle
        projectShortDescription
        slug
        projectImages {
          fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    image: datoCmsResidentialPage {
      residentialProjectPageImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    page: datoCmsResidentialPage {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default Residential;
