import { createGlobalStyle } from 'styled-components';
import 'typeface-poppins';
import 'typeface-roboto';
import 'react-image-lightbox/style.css';

const Global = createGlobalStyle`
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, button {
    font-family: 'Poppins', sans-serif;
}

p, a, li, ul, ol, span, blockquote {
    font-family: 'Roboto', sans-serif;
}
`;

export default Global;
