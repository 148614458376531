import styled from 'styled-components';
import media from '../MediaQueries';

export const ProjectWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px;
  ${media.tablet`
  padding: 40px;
  `}
  ${media.mobile`
  padding: 20px;
  `}
`;
