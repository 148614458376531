import React from 'react';
import {
  Wrapper,
  CTA,
  CTAText,
  Top,
  Bottom,
  BoxWrapper,
  Box,
  BB,
  BW,
  Logo,
  LogoWrapper,
  NavWrapper,
  Nav,
  NavItem,
  BottomInner,
  CopyWrapper,
  Copy,
  SocialWrapper,
  SocialNav,
} from './Footer.css';
import { graphql, StaticQuery, Link } from 'gatsby';
// import Facebook from '../../images/icon-facebook.svg';
// import Instagram from '../../images/icon-instagram.svg';
// import Yelp from '../../images/icon-yelp.svg';
import consts from '../../util/consts';

const d = new Date();
const y = d.getFullYear();

const Footer = () => {
  return (
    <Wrapper>
      <CTA>
        {/* <CTAText>Lets Work Together</CTAText> */}
        <BoxWrapper>
          <Box>
            <BB>
              <Link to={'/contact'}>Contact Us Now</Link>
            </BB>
            <BW>
              <a href={`tel:${consts.phone}`}>{consts.phoneFull}</a>
            </BW>
          </Box>
        </BoxWrapper>
      </CTA>
      <Top>
        <LogoWrapper>
          <StaticQuery
            query={graphql`
              query {
                file(relativePath: { eq: "logo.png" }) {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            `}
            render={data => <Logo fluid={data.file.childImageSharp.fluid} />}
          />
        </LogoWrapper>
        <NavWrapper>
          <Nav>
            <NavItem to="/">Home</NavItem>
            <NavItem to="/residential">Residential</NavItem>
            <NavItem to="/commercial">Commercial</NavItem>
            <NavItem to="/services">Services</NavItem>
            <NavItem to="/contact">Contact</NavItem>
          </Nav>
        </NavWrapper>
      </Top>
      <Bottom>
        <BottomInner>
          <CopyWrapper>
            <Copy>
              <span>Copyright {y} | JB Marble Co.</span>
            </Copy>
          </CopyWrapper>
          <SocialWrapper>
            <SocialNav>
              {/* <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://facebook.com"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://instagram.com"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://yelp.com"
              >
                <img src={Yelp} alt="Yelp" />
              </a> */}
            </SocialNav>
          </SocialWrapper>
        </BottomInner>
      </Bottom>
    </Wrapper>
  );
};

export default Footer;
