import { css } from 'styled-components';
import Breakpoints from './Breakpoints';

const media = Object.keys(Breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${Breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  acc[`${label}_up`] = (...args) => css`
    @media screen and (min-width: ${Breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default media;
