import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Wrapper,
  ProjectCard,
  CardImageWrapper,
  Image,
  Content,
  Button,
} from './ProjectFeed.css';
import { map } from 'lodash';

const ProjectFeed = ({ projects }) => {
  return (
    <Wrapper>
      {map(projects, (project, index) => {
        return (
          <ProjectCard key={index}>
            <CardImageWrapper>
              <Image fluid={project.projectImages[0].fluid} />
            </CardImageWrapper>
            <Content>
              <h2>{project.projectTitle}</h2>
              <p>{project.projectShortDescription}</p>
            </Content>
            <Link to={`/project/${project.slug}`}>
              <Button>View Project</Button>
            </Link>
          </ProjectCard>
        );
      })}
    </Wrapper>
  );
};

ProjectFeed.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectFeed;
